import axios from './_dotnetConfiguration';
import { GetAllCouncelors } from '../store/interface';
import {counsellorDetails}  from '../store/interface';

// export const getInstitutionalboard = async() =>{
//     debugger;
//     return await axios.get<InstitutionalResponse>('/Leaderboard/GetInstitutionalRankings');
//   }

export const  GetCouncellors = async(pageNumber:number,pageSize:number) =>{
    return await axios.get<GetAllCouncelors>(`/Councellor/GetAllCouncellors?pageNumber=${pageNumber}&pageSize=${pageSize}`);
}

// export const  GetCouncellors = async() =>{
//     return await axios.get<counsellorDetails>('/Councellor/GetAllCouncellors');
// }