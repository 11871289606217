import React, { Fragment, useState } from 'react';
import  ReactSlider  from 'react-slider';
import './doubleSlider.css'; // Create a CSS file for styles

const DoubleSlider: React.FC = () => {
  const [values, setValues] = useState<[number, number]>([20, 80]);

  const handleSliderChange = (newValues: [number, number]) => {
    setValues(newValues);
  };

  return (
    <Fragment>
        <div style={{ padding: '20px' }}>
      <ReactSlider
        min={0}
        max={100}
        value={values}
        onChange={handleSliderChange}
        renderThumb={(props, state) => <div {...props} className="thumb" />}
        renderTrack={(props) => <div {...props} className="track" />}
      />
     
    </div>
     <p>Selected Range: {values[0]} - {values[1]}</p>
    </Fragment>
  );
};

export default DoubleSlider;
